import { useStaticQuery, graphql } from "gatsby";

export default function useAccruentExternalResources() {
  const data = useStaticQuery(graphql`
    query {
        article: allArticle(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
          limit: 1000
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: field_article_product_category {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        blog_entry: allNodeBlogEntry(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        brochure: allNodeBrochure(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        case_study: allNodeCaseStudy(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        infographic: allNodeInfographic(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        podcast_episode: allNodePodcastEpisode(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        knowledge_hub_: allNodeKnowledgeHub(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        press_release: allNodePressRelease(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        video: allNodeVideo(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              fieldHeroMedia: field_hero_media {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    gatsbyImage(width: 10, layout: FULL_WIDTH)
                    url
                  }
                }
              } 
              fieldVideoRef: field_video_ref {
                thumbnail {
                  alt
                }
                relationships {
                  thumbnail {
                    gatsbyImage(width: 10, layout: FULL_WIDTH)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        ebook: allNodeEbook(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        webinar: allNodeWebinar(
          filter: {langcode: {eq: "en"}, field_webinar_type: {ne: "future"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            fieldWebinarType: 
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    url
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
        white_paper: allNodeWhitePaper(
          filter: {langcode: {eq: "en"}, field_target_site: {drupal_internal__target_id: {ne: 451}}, field_visible_on_resource_overvi: {elemMatch: {drupal_internal__target_id: {eq: 451}}}, field_hide_page: {eq: false}, status: {eq: true}}
          sort: {fields: created, order: DESC}
        ) {
          nodes {
            locale: langcode
            entityUrl: path {
              path: alias
            }
            id: drupal_internal__nid
            uuid: drupal_id
            type: node_type {
              id: drupal_internal__target_id
            }
            title
            body {
              value: processed
            }
            fieldTargetSite: field_target_site {
              targetId: drupal_internal__target_id
            }
            status
            fieldResourceDescription: field_resource_description
            entityCreated: created(formatString: "M D Y")
            relationships {
              fieldMediaImage: field_media_image {
                fieldImage: field_image {
                  alt
                }
                relationships {
                  fieldImage: field_image {
                    urlentityCreated: created(formatString: "M D Y")
                    publicUrl
                    placeholderUrl
                    gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                  }
                }
              }
              product: product {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              fieldSolutionType: field_solution_type {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
              industry {
                fieldRemoveFromFilter: field_remove_from_filter
                fieldFilterLabel: field_filter_label
                fieldPageTitle: field_page_title
                id: drupal_internal__tid
                name
              }
            }
          }
        }
      }
    `)


  return data
}

