const stripTrailingSlash = require("./stripTrailingSlash");
const parseAndTruncate = require("./parseAndTruncate");
const prefixLangcode = require('./prefixLangcode')
const removeGatsbyReservedField = require('./removeGatsbyReservedField')
const getImageObject = require("./getImageObject")

// duplicate keys should be removed before sending to context
const duplicateObjKeys = ["relationships.industry", "relationships?.fieldSolutionType",
  "relationships.product", "relationships?.industry", "relationships.fieldMediaImage",
  "relationships.fieldHeroMedia", "relationships.fieldVideoRef"
];

/**
 * Its purpose is to capitalizes the first letter of each word in a string
 *
 * @param {String} str -  input string.
 * @returns {String}
 */
const startCase = (str) => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length === 0) {
    return str;
  }

  return str
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getResourcesContext = (lang, entity, isMinimal = false) => {

  if (!entity) return undefined;
  // Take the first indutryId from the list to set as a parameter to search for related pages
  // to be put inside RecommendedArticles component
  let industryId = "42";
  let productId = "55";
  let solutionId = "91";
  let industryNames = null;
  let solutionNames = null;
  let productNames = null;
  let industryTags = null;
  let solutionTags = null;
  let productTags = null;
  let industryIds = [];
  let solutionIds = [];
  let productIds = [];
  let featuredIndustryNames = [];
  let contentTypeId = [
    {
      id: entity.type?.id,
      label: startCase(entity.type?.id),
    },
  ];

  if (
    entity.relationships?.industry &&
    entity.relationships?.industry[0]
  ) {
    industryId = entity.relationships.industry[0].id?.toString();
  }

  if (
    entity.relationships?.fieldSolutionType &&
    entity.relationships?.fieldSolutionType[0]
  ) {
    solutionId = entity.relationships.fieldSolutionType[0].id?.toString();
  }

  if (
    entity.relationships?.product &&
    entity.relationships?.product[0]
  ) {
    productId = entity.relationships.product[0].id?.toString();
  }

  // Set Industry names into a industryNames variable
  if (
    entity.relationships?.industry &&
    entity.relationships.industry.length !== 0
  ) {
    industryNames = entity.relationships.industry.map(
      (obj) => obj && obj.fieldPageTitle
    );
    industryTags = entity.relationships.industry.map(
      (obj) => obj && obj.name
    );
    industryIds = entity.relationships.industry
      .filter((obj) => obj && obj.fieldRemoveFromFilter !== true)
      .map((obj) => {
        return {
          id: obj?.fieldFilterLabel,
          industryId: obj?.id?.toString(),
          label: obj?.fieldPageTitle,
          targetSideId: obj?.fieldTermTargetSite?.drupalInternalTargetId,
          type: 'industry',
        };
      });
    featuredIndustryNames = entity.relationships.industry
      .filter(
        (obj) =>
          obj &&
          obj.id &&
          obj.fieldPageTitle &&
          obj.fieldFeatured
      )
      .map((obj) => obj?.fieldPageTitle);
  }

  // Set solution names into solutionNames variable
  if (
    entity.relationships?.fieldSolutionType &&
    entity.relationships?.fieldSolutionType.length !== 0
  ) {
    solutionNames = entity.relationships.fieldSolutionType.map(
      (obj) => obj && obj.fieldPageTitle
    );
    solutionTags = entity.relationships.fieldSolutionType.map(
      (obj) => obj && obj.name
    );
    solutionIds = entity.relationships.fieldSolutionType
      .filter((obj) => obj && obj.fieldRemoveFromFilter !== true)
      .map((obj) => {
        return {
          id: obj?.fieldFilterLabel,
          solutionId: obj?.id?.toString(),
          label: obj?.fieldPageTitle,
          targetSideId: obj?.fieldTermTargetSite?.drupalInternalTargetId,
          type: 'solution',
        };
      });
  }
  // Set Product names into solutionNames variable
  if (
    entity.relationships?.product &&
    entity.relationships.product.length !== 0
  ) {
    productNames = entity.relationships.product.map((obj) =>
      obj ? obj.fieldPageTitle : null
    );
    productTags = entity.relationships.product.map((obj) =>
      obj ? obj.name : null
    );
    productIds = entity.relationships.product
      .filter((obj) => obj && obj?.fieldRemoveFromFilter !== true)
      .map((obj) => {
        return {
          id: obj?.fieldFilterLabel,
          productId: obj?.id?.toString(),
          label: obj?.fieldPageTitle,
          type: 'product',
        };
      });
  }

  if (!productIds?.length) {
    productIds = [{ productId: "55" }]
  }
  if (!solutionIds?.length) {
    solutionIds = [{ solutionId: "91" }]
  }
  if (!industryIds?.length) {
    industryIds = [{ industryId: "42" }]
  }

  let fieldTags = entity?.relationships?.fieldTags

  // Add article:created-at into metatags
  const { entityMetatags, entityCreatedKebab } = entity;
  if (Array.isArray(entityMetatags) && entityCreatedKebab) {
    entityMetatags.push({
      property: "article:published_time",
      content: entityCreatedKebab,
    });
  }

  // This is used on resource cards
  // Changing this from body to fieldResourceDescription
  // This new field will be common for all resource types
  let truncatedBody = null;
  if (entity.fieldResourceDescription) {
    truncatedBody = parseAndTruncate(entity.fieldResourceDescription);
  } else {
    if (entity.body?.value) {
      truncatedBody = parseAndTruncate(entity.body.value);
    }
  }

  // truncated fieldTheCompany for Casu Studies
  let truncatedFieldTheCompany = null;
  if (
    entity.fieldTheCompany?.value
  ) {
    truncatedFieldTheCompany = parseAndTruncate(
      entity.fieldTheCompany.value,
      400
    );
  }
  let heroImage = getImageObject(entity?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
  heroImage = heroImage?.gatsbyImage ? heroImage : getImageObject(entity?.relationships?.fieldHeroMedia, {}, "fieldHeroMedia");

  let commonContext = {
    id: entity.id,
    page_type: "resourcePage",
    content_type: contentTypeId[0].id,
    locale: lang,
    heroImage,
    fieldVideoRef: entity.relationships?.fieldVideoRef,
    fieldMetaTags: entity.fieldMetaTags,
    title: entity.title,
    subTitle: entity.fieldSubtitle,
    fieldResourceDescription: entity.fieldResourceDescription,
    body: entity.body,
    fieldTargetSite: entity.fieldTargetSite,
    contentTypeId,
    entityCreated: entity.entityCreated,
    solutionIds,
    productIds,
    industryIds,
    uuid: entity.uuid,
    fieldExternalLink: entity.fieldExternalLink,
    fieldLinkOriginalArticle: entity.fieldLinkOriginalArticle,
    fieldPublishDate: entity.fieldPublishDate,
    created: entity.entityCreated,
    fieldTags,
    entityUrl: {
      ...entity.path,
      path: stripTrailingSlash(prefixLangcode(entity.entityUrl?.path, entity.locale)),
    },
  }
  const entityContext = removeGatsbyReservedField(entity, duplicateObjKeys)
  context = {
    ...entityContext,
    ...commonContext,
    industryId,
    solutionId,
    productId,
    industryRegex: "/\\b(\\w*resources\\w*)\\b/",
    industryNames,
    industryTags,
    featuredIndustryNames,
    solutionNames,
    solutionTags,
    productNames,
    productTags,
    entityMetatags,
    truncatedBody,
    truncatedFieldTheCompany,
  }

  if (isMinimal) {
    context = {
      ...commonContext,
      truncatedBody: truncatedBody ? truncatedBody : truncatedFieldTheCompany,
      type: entityContext?.type
    }
  }

  return context;
}

module.exports = getResourcesContext;