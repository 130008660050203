import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef,
  Fragment,
} from "react";
import useResourceQueryParam from "src/components/helpers/useResourceQueryParam";
import Resources, {
  IndividualResourceCard,
} from "../components/solutions/Resources";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import isEmpty from "src/components/helpers/isEmpty.js";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import HighlightCard from "src/components/resources/main/HighlightCard";
import findSiteType from "src/components/helpers/findSiteType";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import useResourceOverviewData from "src/hooks/useResourceOverviewData";
import { useLocation } from '@reach/router';
import UkToUsLink from "src/components/helpers/UkToUsLink";
import getResourcesLabel from "src/../helpers/getResourcesLabel";
import { FilterForm,SEO } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import Pagination from "src/components/Pagination";
import { RESOURCE_OVERVIEW_PAGE } from "src/constants";
/**
 * @Main RESOURCES PAGE COMPONENT
 * The data from Context is being fetched and set to Context in src/components/Layout/Layout.js file
 * The reason is that this way the whole app is aware of this state only using Context
 * whenever necessary.
 */
const ResourcesMainPage = (props) => {
  const {
    pageContext,
  } = props;
  const { locale } = pageContext;
  const nodes = useResourceOverviewData(locale);
  const {
    uniqueTypes,
    uniqueIndustries,
    uniqueSolutions,
    uniqueProducts,
    filters,
    setFilter,
    allQueryParams
  } = useContext(ResourcesContext);
  useResourceQueryParam();
  const [activeResourcePage, setActivePage] = useState(1);
  const [breadcrumbs, setBreadcrumbs] = useState([
    { title: "Home", link: "/" },
    { title: "Resources", link: "/resources" },
  ]);

  const isMc = findSiteType('mc');
  const isEMS = findSiteType('ems');
  const [srFilterDescription, setSrFilterDescription] = useState(
    "No filters applied"
  );
  const topRef = useRef(null)
  const location = useLocation();
  const handleSetFilter = (filterData) => {
    setFilter(filterData)
    topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  useEffect(() => {
    const filterNamesHolder = [];

    const crumbsHolder = [{ title: "Home", link: "/" }, { title: "Resources" }];

    const queryKeys = allQueryParams ? Object.keys(allQueryParams) : [];

    queryKeys.forEach((key) => {
      const value = allQueryParams[key];
      if (value.param) {
        if (key === "industry") {
          filterNamesHolder.push(
            uniqueIndustries.find((industry) => industry.value === value.param)
              ?.label
          );
        } else if (key === "solution") {
          filterNamesHolder.push(
            uniqueSolutions?.find((solution) => solution.value === value.param)
              ?.label
          );
        } else if (key === "product") {
          filterNamesHolder.push(
            uniqueProducts?.find((product) => product.value === value.param)
              ?.label
          );
        } else {
          filterNamesHolder.push(
            uniqueTypes?.find((type) => type.value === value.param)?.label
          );
        }
      }
    });

    if (filterNamesHolder.length !== 0) {
      crumbsHolder.push({
        title: `Filtered (${filterNamesHolder.sort().join(", ")})`,
      });
      let descriptions = filterNamesHolder.join(", ");
      if (filterNamesHolder.length > 1) {
        const last = filterNamesHolder.pop();
        descriptions = filterNamesHolder.join(", ") + " and " + last;
      }
      const srDescription = `Filtered by ${descriptions}`;
      setSrFilterDescription(srDescription);
    } else {
      setSrFilterDescription("No filters applied");
    }

    setBreadcrumbs(crumbsHolder);
  }, [allQueryParams, uniqueIndustries, uniqueSolutions, uniqueProducts, uniqueTypes]);

  const isProductType = (type, filterby, key) => {
    if (key !== 'product') {
      return false;
    }
    switch (type) {
      case "mc":
        return (filterby?.toLowerCase() === '/products/maintenance-connection')
      case "ems":
        return (filterby?.toLowerCase() === '/products/ems')
      default:
        return false
    }
  }
  
  useEffect(() => {
    let activeFilter = hasFilterActive()
    if(activeFilter){
      setActivePage(1)
    }
  },[filters])

  /**
   * @NoFilter
   */

  const noFilterContent = useMemo(() => {
    const resources = createRelatedResources(nodes, {
      hasDescription: true,
    });
    const totalNumberOfPages = Math.ceil(resources.length/RESOURCE_OVERVIEW_PAGE)
    const filtered = resources.slice((activeResourcePage-1)*RESOURCE_OVERVIEW_PAGE, activeResourcePage * RESOURCE_OVERVIEW_PAGE)
    return (
      <>
        <div className="mt-6">
          <Resources
            isCarousel={false}
            className="mb-7"
            data={filtered}
            shouldNotResetFilterWhenClickingLink
          />
          <Pagination setActivePage={setActivePage} totalNumberOfPages={totalNumberOfPages} activeResourcePage={activeResourcePage}/>
        </div>
      </>
    );
  }, [nodes, uniqueTypes, filters, setFilter]);

  /**
   * @Filtered
   */
  const filteredContent = useMemo(() => {
    const filterKeys = filters ? Object.keys(filters) : [];
   
    let filteredResources = createRelatedResources(nodes, {
      hasDescription: true,
    });

    filterKeys.forEach((key) => {
      if (!isEmpty(filters[key])) {
        filteredResources = filteredResources.filter(
          (item) => {
            if (isProductType('mc', filters[key], key)) {
              return item?.targetSiteId === 453 ||
                (item?.targetSiteId !== 452 &&
                  ((Array.isArray(item[key]) && item[key]?.some((obj) => obj.id === filters[key])) ||
                    (typeof item[key] === "string" && item[key] === filters[key])))
            } else if (isProductType('ems', filters[key], key)) {
              return item?.targetSiteId === 452 ||
                (item?.targetSiteId !== 453 &&
                  ((Array.isArray(item[key]) && item[key]?.some((obj) => obj.id === filters[key])) ||
                    (typeof item[key] === "string" && item[key] === filters[key])))
            } else {
              return (Array.isArray(item[key]) && item[key]?.some((obj) => obj.id === filters[key])) ||
                (typeof item[key] === "string" && item[key] === filters[key])
            }
          }
        );
      }
    });

    if (!filteredResources || filteredResources.length === 0) {
      return (
        <div className="tw-flex tw-mt-[24px] tw-items-center tw-justify-center">
          {locale === "en" ?
            <p className="tw-flex-none md:tw-w-[41%] tw-w-full tw-items-center tw-text-[18px] tw-font-Roboto tw-text-center tw-font-bold tw-pt-[14px] tw-text-accruent_base_color">
              No resources are available with your selection. Modify your search
              criteria to see more great content!
            </p>
            :
            <p className="tw-flex-none md:tw-w-[41%] tw-w-full tw-items-center tw-text-[18px] tw-font-Roboto tw-text-center tw-font-bold tw-pt-[14px] tw-text-accruent_base_color">
              We are still building out our localised resource library. The good news is that more resources are available on our {UkToUsLink(location?.href)} , so please give it another go !
            </p>
          }
        </div>
      );
    } else {
    
      return (
        <div className={`solutions-resources-wrapper resource-overview-cards filtered-resources-wrapper ${!filters?.['content_type'] ? "mt-6" : ""}`}>
          {!!filters?.['content_type'] && !!filteredResources[0]?.content_type && (
            <div className="header">
              <div className="columns">
                <div className="title column md:tw-justify-start md:tw-mb-[34px] md:tw-mt-[30px] md:tw-p-0 tw-mb-[39px] tw-mt-[54px]">
                  <h2 className="title-4">
                    {!!filteredResources.length && (
                      <div className="resourceResultCount tw-text-accruent_base_color tw-font-Roboto tw-text-[12px] tw-font-medium tw-leading-[16px] tw-tracking-[1.8px] tw-mb-[8px] tw-uppercase">{`${filteredResources.length} Results`}</div>
                    )}
                    <div className="resources-overview-heading">{`${getResourcesLabel(filteredResources[0]?.content_type)}`}</div></h2>
                </div>
              </div>
            </div>
          )}
          <div className="tw-flex tw-flex-wrap tw-gap-5 tw-justify-center" aria-live="polite">
            {filteredResources.map((resource, i) => (
              <IndividualResourceCard
                data={resource}
                key={resource.title + i}
                shouldNotResetFilterWhenClickingLink
              />
            ))}
          </div>
        </div>
      );
    }
  }, [nodes, filters]);

  /**
   * @check if there is filter
   */
  const hasFilterActive = () => {
    for (const key in filters) {
      if (!isEmpty(filters[key])) return true;
    }
    return false;
  };

  let hreflanfAlternatives = [
    { href: '/resources', hreflang: 'en' },
    { href: '/resources', hreflang: 'x-default' }
  ]

  if (!isMc && !isEMS) hreflanfAlternatives.push({ href: '/uk/resources', hreflang: 'en-gb' })
  const OGMarkup = getOGMarkup("Resources", 'resource');
  const isUKResourcesPage = location.pathname.includes('/uk/resources');
  const descriptionContent = isUKResourcesPage
    ? 'View Accruent articles, case studies, eBooks, webinars, & videos to get tips & best practices for optimizing the facilities & asset management lifecycle.'
    : 'Get tips, product support, and best practices that will help you maximize and optimize the facilities and asset management lifecycle.';
  
    const metatags = [
      { name: 'title', content: `Resources | Accruent` },
      { name: 'description', content: descriptionContent },
    ];
  const pageDataPath = getPageDataJsonPath(`/${locale == 'en-gb' ? "uk/resources" : "resources"}`)

  const search = location.search;
  hreflanfAlternatives = search.length > 0 ? [] : hreflanfAlternatives
  return (
    <div className="section tw-relative tw-h-full">
      <SEO
        lang={locale}
        alternatives={hreflanfAlternatives}
        title="Resources"
        pageUrl={`/${locale == 'en-gb' ? "uk/resources" : "resources"}`}
        OGMarkup={OGMarkup}
        meta={metatags}
        preloads= {[pageDataPath]}
      />
      <div className="container">
        <section className="tw-relative tw-mt-0">
          <div>
            <div>
              <FilterForm />
              <div ref={topRef} ></div>
              {hasFilterActive() ? filteredContent : noFilterContent}

              <HighlightCard
                title={"See It In Action"}
                body={"Explore how our software solutions can help optimize all aspects of your operations"}
                buttonText={(isMc || isEMS) ? "WATCH A DEMO" : "SCHEDULE A DEMO"}
                buttonLink={`${isMc ? "/watch-demo-page" : (isEMS ? "/watch-demo" : (locale == "en" ? "/request-demo" : "/uk/contact-us"))}`}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ResourcesMainPage;
