const getAllResourceData = (data) => {
    if (!data) return []
    let tmp = []
    Object.values(data)?.forEach(d => {
        tmp = [...tmp, ...d?.nodes]
    })
    
    tmp = tmp?.filter(t=>!!t.status)
  
    return tmp;
}

export default getAllResourceData;