import React from 'react'
import { LocalizedLink } from 'src/components/commonComponents';

const HighlightCard = ({title, body , buttonText, buttonLink}) => {
  return (
      <div className='highlight-card tw-shadow-[1px_1px_40px_#86858529] tw-rounded-[24px_24px_0px_24px] tw-opacity-1 tw-bg-accruent_gray tw-m-[52px_0_0_0] md:tw-m-[76px_16.18%_0px_16.18%] tw-p-[28px_0px] md:tw-pt-[56px] md:tw-pl-[8%] md:tw-pr-[8%] tw-text-center'>
          {title && <div className='highlight-title tw-text-[28px] md:tw-text-[32px] tw-p-[0_40px] md:p-0 tw-leading-[42px] md:tw-leading-[48px] tw-text-heading_color tw-font-Poppins tw-font-bold tw-mb-0'>{title}</div>
          }
          {body && <p className='highlight-body md:tw-text-[22px] tw-text-[18px] tw-mt-[13px] tw-p-[0px_22px] tw-leading-[24px] md:text-[22px] md:leading-[29px] tw-text-heading_color md:tw-mb-0 md:tw-mt-[8px]'>{body}</p>
          }
          {buttonText && <LocalizedLink
              to={buttonLink}
              className="solid-btn tw-inline-block tw-bg-accruent_dark_imp_blue tw-border-solid tw-border-[2px] tw-border-accruent_dark_imp_blue tw-text-accruent_white tw-rounded-[12px] tw-p-[14px_24px] tw-font-bold  highlight-btn tw-m-0 tw-mt-[22px] tw-text-[16px] tw-font-Poppins hover:tw-bg-accruent_white hover:border-accruent_dark_imp_blue hover:tw-text-accruent_dark_imp_blue"
          >
              {buttonText}
          </LocalizedLink>
          }
      </div>
  )
}

export default HighlightCard