import { useEffect, useContext } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import ResourcesContext from "src/components/helpers/ResourcesMainPageContext.js";
import isEmpty from "src/components/helpers/isEmpty";

/**
 * Function to make the url query params match the Context and the UI for Resources Main page.
 * Here it deals with the functionality of adding to the context and the query at the same time
 * and when the page loads with params, the filters are set automatically. But here there is no functionality
 * to take the params off or reset anything. That should be done in a specific component, like the HeroFilter.
 */
const useResourceQueryParam = () => {
  const {
    filters,
    setFilter,
    setAllQueryParams,
    uniqueIndustries,
    uniqueSolutions,
    uniqueProducts,
    uniqueTypes,
  } = useContext(ResourcesContext);

  // For every filter from Resource use the hook to read and set query params
  const [contentTypeParam, setContentTypeParam] = useQueryParam(
    "content_type",
    StringParam
  );
  const [solutionParam, setSolutionParam] = useQueryParam(
    "solution",
    StringParam
  );
  const [productParam, setProductParam] = useQueryParam(
    "product",
    StringParam
  );
  const [industryParam, setIndustryParam] = useQueryParam(
    "industry",
    StringParam
  );

  /**
   * This object is set to Context to be reachable in other components
   */
  const allQueryParams = {
    content_type: {
      param: contentTypeParam,
      setParam: setContentTypeParam,
    },
    solution: {
      param: solutionParam,
      setParam: setSolutionParam,
    },
    product: {
      param: productParam,
      setParam: setProductParam,
    },
    industry: {
      param: industryParam,
      setParam: setIndustryParam,
    },
  };

  /**
   * This deals with adding the Context when the page is load with
   * query params on it's url. It is called everytime a parameter is added to the URL
   */
  useEffect(() => {
    const filterHolder = { ...filters };
    if(filters)
    Object.keys(filters).forEach((filterKey) => {
      // if the param query exists (not undefined) set the filter to filterHolder
      if (allQueryParams[filterKey].param) {
        let isValid = false;
        // Check if the value in URL search params are valid (are inside the unique types, industries and solutions)
        if (filterKey === "content_type") {
          isValid = uniqueTypes?.some(
            ({ value }) => value === allQueryParams[filterKey].param
          );
          // If it's not valid, take the param off the search url
          !isValid && allQueryParams[filterKey].setParam(undefined);
        } else if (filterKey === "solution") {
          isValid = uniqueSolutions?.some(
            ({ value }) => value === allQueryParams[filterKey].param
          );
          !isValid && allQueryParams[filterKey].setParam(undefined);
        } else if (filterKey === "industry") {
          isValid = uniqueIndustries?.some(
            ({ value }) => value === allQueryParams[filterKey].param
          );
          !isValid && allQueryParams[filterKey].setParam(undefined);
        } else if (filterKey === "product") {
          isValid = uniqueProducts?.some(
            ({ value }) => value === allQueryParams[filterKey].param
          );
          !isValid && allQueryParams[filterKey].setParam(undefined);
        }
        if (isValid) {
          filterHolder[filterKey] = allQueryParams[filterKey].param;
        }
      }
      // Set the complete Filter then to Context
      setFilter(filterHolder);
    });
    // eslint-disable-next-line
  }, [contentTypeParam, solutionParam, productParam, industryParam]);

  /**
   * When the filter changes, in other words, when one filter is clicked in the HeroFilter dropdown
   * the filter is already set to Context and here it is set also to URL as a query, so they both are in sync all the time.
   * This is called evertime `filter` changes
   */
  useEffect(() => {
    // For every key in filters, check if the value is empty, if is not empty set to url query
    if(filters)
    Object.keys(filters).forEach((filterKey) => {
      if (
        !isEmpty(filters[filterKey]) 
        // &&
        // filters[filterKey] !== allQueryParams[filterKey].param
      ) {
        allQueryParams[filterKey].setParam(filters[filterKey], "replaceIn");
      }
    });
    // eslint-disable-next-line
  }, [filters]);

  /**
   * The `allQueryParams` object above is set to Context, so every component can set or take off
   * query parameters independent from this file. This is called everytime a query param changes and
   * updates that object in Context so the components can have an up to date info about the params.
   */
  useEffect(() => {
    if(setAllQueryParams)
    setAllQueryParams(allQueryParams);
    // eslint-disable-next-line
  }, [contentTypeParam, solutionParam, productParam, industryParam]);
};

export default useResourceQueryParam;
