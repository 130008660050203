import React, { useEffect, useState } from "react";
import angleRight from "../../static/angle-right.svg";
import angleDoubleRight from "../../static/angle-double-right.svg";
import angleLeft from "../../static/angle-left.svg";
import angleDoubleLeft from "../../static/angle-double-left.svg";
const makePageArr = (mid, pagesCount) => {
  let arr = [], maxPageCountToShow = 9, midPoint = 5
  if (mid > pagesCount - midPoint) {
    for (let i = pagesCount - maxPageCountToShow-1; i <= pagesCount; i++) {
      arr.push(i)
    }
  }
  else if (mid > midPoint) {
    for (let i = mid - (midPoint-1); i <= mid + (midPoint-1); i++) {
      arr.push(i);
    }
  }
  else {
    for (let i = 1; i <= maxPageCountToShow; i++) {
      arr.push(i)
    }
  }
  return arr;
}
const Pagination = ({activeResourcePage, setActivePage, totalNumberOfPages }) => {
  const [midPage, setMidPage] = useState(5);
  let pagesCount = totalNumberOfPages;
  let arr = makePageArr(midPage, pagesCount)

  const onSetPage = (page) => {
    setActivePage(page);
    setMidPage(page);
    if(document){
      let scrollPos = document.getElementById("hero-filter-wrapper")
      setTimeout(() => {
        scrollPos.scrollIntoView({
          behavior: "smooth"
        })
      },100)
    }
  }
  const pageStyle = `tw-cursor-pointer lg:tw-p-[7px_10px] !tw-mb-0 tw-text-accruent_black tw-rounded-[8px] md:hover:tw-bg-accruent_sapphire md:hover:tw-text-accruent_white tw-text-[16px] lg:tw-text-[18px] ${activeResourcePage <= 9 ? "tw-p-[7px_7px]" : "tw-p-[6px_4px]"}`
  return (
    <div className="custom-pagination tw-flex tw-items-center tw-mt-[40px] tw-justify-center">
      {activeResourcePage !== 1 && 
        <img onClick={() => onSetPage(1)} src={angleDoubleLeft} className="svg-inline--fa fa-angle-double-left tw-cursor-pointer tw-text-accruent_dark_imp_blue tw-h-[1.25rem] tw-mr-1 md:tw-mr-[15px] arrow-icon"/>
      }
      {activeResourcePage !== 1 && 
        <img onClick={() => onSetPage(activeResourcePage-1)} src={angleLeft} className="svg-inline--fa fa-angle-left tw-cursor-pointer tw-text-accruent_dark_imp_blue tw-h-[1.25rem] tw-mr-1 md:tw-mr-[15px] arrow-icon"/>
      }
      {pagesCount>9 && midPage > 5 && "..."}
      <div className="tw-flex pagination-container lg:tw-gap-[5px] md:tw-gap-[2px] tw-justify-center">
        {!!pagesCount && pagesCount <= 9 ? <>
          {new Array(pagesCount).fill(0).map((_, index) => {
            return (
              <p className={`page-style ${pageStyle} ${activeResourcePage === index+1 ? "active tw-bg-accruent_sapphire tw-text-accruent_white": ""}`} key={index} onClick={() => onSetPage(index + 1)}>{index + 1}</p>
            )
          })}
        </> :
          <>
            {!!arr.length && arr.map((page, index) => {
              return (
                <p className={`page-style ${pageStyle} ${activeResourcePage === page ? "active tw-bg-accruent_sapphire tw-text-accruent_white": ""}`} key={index} onClick={() => onSetPage(page)}>{page}</p>
              )
            })}
          </>
        }

      </div>
      {pagesCount>9 && midPage <= pagesCount - 5 && "..."}
      {activeResourcePage !== pagesCount && 
      <img onClick={() => onSetPage(activeResourcePage+1)} src={angleRight} decoding="async" className="svg-inline--fa fa-angle-right tw-cursor-pointer tw-text-accruent_dark_imp_blue tw-h-[1.25rem] tw-ml-1 md:tw-ml-[15px] arrow-icon"/>
      }
      {activeResourcePage !== pagesCount && 
      <img onClick={() => onSetPage(pagesCount)} src={angleDoubleRight} decoding="async" className="svg-inline--fa fa-angle-double-right tw-cursor-pointer tw-text-accruent_dark_imp_blue tw-h-[1.25rem] tw-ml-1 md:tw-ml-[15px] arrow-icon"/>
      }
   
    </div>
  )
}
export default Pagination;