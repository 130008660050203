import { useStaticQuery, graphql } from "gatsby";

export default function useUkExternalResource() {
  const data = useStaticQuery(graphql`
    query {
      article: allArticle(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}
        sort: {fields: created, order: DESC}
        limit: 1000
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: field_article_product_category {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      blog_entry: allNodeBlogEntry(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      brochure: allNodeBrochure(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      case_study: allNodeCaseStudy(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      infographic: allNodeInfographic(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      podcast_episode: allNodePodcastEpisode(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      knowledge_hub_: allNodeKnowledgeHub(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      press_release: allNodePressRelease(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      video: allNodeVideo(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      ebook: allNodeEbook(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      webinar: allNodeWebinar(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          fieldWebinarType: 
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
      white_paper: allNodeWhitePaper(
        filter: {field_target_site: {drupal_internal__target_id: {ne: 451}}, status: {eq: true}, field_resource_options: {eq: "show_on_accruent_uk_overview_page"}}        
        sort: {fields: created, order: DESC}
      ) {
        nodes {
          locale: langcode
          entityUrl: path {
            path: alias
          }
          id: drupal_internal__nid
          uuid: drupal_id
          type: node_type {
            id: drupal_internal__target_id
          }
          title
          body {
            value: processed
          }
          fieldTargetSite: field_target_site {
            targetId: drupal_internal__target_id
          }
          status
          fieldResourceDescription: field_resource_description
          relationships {
            fieldMediaImage: field_media_image {
              fieldImage: field_image {
                alt
              }
              relationships {
                fieldImage: field_image {
                  url
                  publicUrl
                  placeholderUrl
                  gatsbyImage(fit: COVER, width: 376, layout: FULL_WIDTH, placeholder:BLURRED)
                }
              }
            }
            product: product {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            fieldSolutionType: field_solution_type {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
            industry {
              fieldRemoveFromFilter: field_remove_from_filter
              fieldFilterLabel: field_filter_label
              fieldPageTitle: field_page_title
              id: drupal_internal__tid
              name
            }
          }
        }
      }
    }
    `)

  return data
}