/**
 *
 * @param {Array | String} data -  resources types 
 */


const resourcesMap = {
    "article": "Articles",
    "blog_entry": "Blog Entries",
    "brochure": "Brochures",
    "case_study": "Case Studies",
    "company_news": "Company News",
    "ebook": "ebooks",
    "infographic": "Infographics",
    "knowledge_hub_": "Knowledge Hub",
    "podcast_episode": "Podcast Episodes",
    "press_release": "Press Releases / News",
    "video": "Videos",
    "webinar": "Webinars",
    "white_paper": "White Papers"
}
const getResourcesLabel = (data) => {
  if(!Array.isArray(data)) return resourcesMap[data?.toLowerCase()]

  return  data?.map(res=>resourcesMap[res?.toLowerCase()])
}

export default getResourcesLabel