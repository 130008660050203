/*
This function is used for remove reserved field of PageContext from an object before passing
 it as context in  createPage function of  Gatsby
*/
const resevedFieldUsedByPageObj =
    ["path",
        "matchPath",
        "component",
        "componentChunkName",
        "pluginCreator___NODE",
        "pluginCreatorId"]

const removeGatsbyReservedField = (obj,extraFields) => {
    if (!obj) return obj
    let keys = [...resevedFieldUsedByPageObj]
    if(Array.isArray(extraFields)){
        keys = [...keys,extraFields]
    }
  
    keys?.forEach(field => {
        delete obj[field]
    })

    return obj;

}

module.exports = removeGatsbyReservedField;