import React, {
  useContext
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import useUkExternalResource from "./resources/useUkExternalResources";
import getAllResourceData from "src/components/helpers/getAllResourceData";
import getResourcesLabel from "src/../helpers/getResourcesLabel";
const getResourcesContext = require('src/../helpers/getResourcesContext')
const site_vars = require("src/get_site_data");
const target_site_tid = site_vars.drupal_site_id
const currentExternslResources =
  (target_site_tid == 452) ? require('./resources/useEMSExternalResources') :
    (target_site_tid == 453) ? require('./resources/useMCExternalResources') :
      require('./resources/useAccruentExternalResources');

export default function useResourceOverviewData(locale = 'en') {

  const data = useStaticQuery(graphql`
  {
    allSitePage: allSitePage(
      filter: {
        context: {fieldHidePage: {ne: true}, fieldWebinarType: {ne: "future"} }
        componentChunkName: {
          eq: "component---src-templates-common-resources-template-js"
        }
      }
    ) {
      nodes {
        ...resourceCards
      }
    }
  }
`);

  const {
    uniqueTypes
  } = useContext(ResourcesContext);
  let externalResources = currentExternslResources?.default()
  if (locale === "en-gb") {
    externalResources = useUkExternalResource();
  }
  externalResources = getAllResourceData(externalResources);
  const externaltranslatedResources = []
  const localuniqueTypes = {}

  externalResources?.forEach(entity => {
    if (entity && entity?.fieldTargetSite?.targetId) {
      if (entity?.fieldWebinarType === "future") return;
      const context = getResourcesContext(locale, entity, true)
      if (context) {
        externaltranslatedResources.push({ context: context, path: context?.entityUrl?.path });
      }

    }
  });

  let dataTranslated = data?.allSitePage?.nodes?.filter(entity => {
    return (entity?.context?.locale === locale)
  })

  dataTranslated = dataTranslated ? [...dataTranslated, ...externaltranslatedResources] : externaltranslatedResources
  dataTranslated?.forEach(entity => {
    let context = entity?.context
    if (context) {
      let type = {
        value: context?.contentTypeId?.[0]?.id,
        label: getResourcesLabel(context?.contentTypeId?.[0]?.id),
      }
      localuniqueTypes[type.value] = type;
    }
  })

  Object.values(localuniqueTypes).forEach(localuniqueType => {
    const isAvaillable = uniqueTypes?.find(uniqueType => uniqueType.value === localuniqueType.value)
    if (!isAvaillable) {
      uniqueTypes?.push(localuniqueType)
    }
  })

  uniqueTypes?.sort(function (a, b) {
    if (a.value < b.value) { return -1; }
    if (a.value > b.value) { return 1; }
    return 0;
  })

  dataTranslated.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    let diff = 0
    try {
      diff = new Date(b.context.entityCreated) - new Date(a.context.entityCreated);
    } catch (error) {

    }
    return diff;
  })

  return dataTranslated;
}
